var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"location"}},[_vm._v("Koho chcete kontaktovať: *")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.localCity),expression:"localCity"}],staticClass:"form__item__input form__item__select",attrs:{"name":"location","id":"location","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.localCity=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.branches),function(branch){return _c('option',[_vm._v(_vm._s(branch))])}),0)]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"type"}},[_vm._v("Typ:")]),_vm._v(" "),_c('select',{staticClass:"form__item__input form__item__select",attrs:{"name":"type","id":"type","rows":"5"}},_vm._l((_vm.types),function(type){return _c('option',[_vm._v(_vm._s(type))])}),0)]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),(_vm.successMessage)?_c('div',{staticClass:"form-status alert-success"},[_vm._v("Dakujeme, budeme Vás kontaktovať.")]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"name"}},[_vm._v("Meno: *")]),_vm._v(" "),_c('input',{staticClass:"form__item__input",attrs:{"type":"text","id":"name","name":"name","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"email"}},[_vm._v("E-mail: *")]),_vm._v(" "),_c('input',{staticClass:"form__item__input",attrs:{"type":"email","id":"email","name":"email","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"phone"}},[_vm._v("Telefónne číslo: *")]),_vm._v(" "),_c('input',{staticClass:"form__item__input",attrs:{"type":"tel","id":"phone","name":"phone","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"city"}},[_vm._v("Obec:")]),_vm._v(" "),_c('input',{staticClass:"form__item__input form__item__input",attrs:{"name":"city","id":"city","rows":"5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"size"}},[_vm._v("Veľkosť:")]),_vm._v(" "),_c('input',{staticClass:"form__item__input form__item__input",attrs:{"name":"size","id":"size"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"price"}},[_vm._v("Cena:")]),_vm._v(" "),_c('input',{staticClass:"form__item__input form__item__input",attrs:{"name":"price","id":"price"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('label',{staticClass:"form__item__label",attrs:{"for":"message"}},[_vm._v("Správa: *")]),_vm._v(" "),_c('textarea',{staticClass:"form__item__input form__item__textarea",attrs:{"name":"message","id":"message","rows":"5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item__wrap"},[_c('div',{staticClass:"form__checkbox__wrap"},[_c('label',{staticClass:"checkbox",attrs:{"for":"checkbox_1"}},[_c('input',{attrs:{"name":"checkbox_1","value":"true","type":"checkbox","id":"checkbox_1","required":""}}),_vm._v(" "),_c('span',{staticClass:"checkbox-label"},[_vm._v("Súhlasím so spracovaním "),_c('span',{staticClass:"d-inline-block"},[_c('a',{staticClass:"checkbox-label__link",attrs:{"href":"#","target":"_blank"}},[_vm._v("osobných údajov")]),_vm._v(" *")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__button__wrap form__button__wrap--center"},[_c('button',{staticClass:"btn btn-primary form__button__btn",attrs:{"type":"submit"}},[_vm._v("Odoslať")])])
}]

export { render, staticRenderFns }