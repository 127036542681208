<template>
    <div class="form">
        <form>
            <div class="form__item__wrap">
                <label for="price" class="form__item__label">Výška úveru:</label>
                <input @change="calculateResult" type="number" id="price" name="price" class="form__item__input"  v-model="localPrice" required>
            </div>
            <div class="form__item__wrap">
                <label for="payment" class="form__item__label">Splatnosť:</label>
                <input @change="calculateResult" type="number" id="payment" name="payment" class="form__item__input"  v-model="payment" required>
            </div>
            <div class="form__item__wrap">
                <label for="rate" class="form__item__label">Úroková sadzba:</label>
                <input @change="calculateResult" type="number" id="rate" name="rate"  step="0.1" class="form__item__input" v-model="rate" required>
            </div>
            <div class="form__button__wrap form__button__wrap--between">
                <a href="#contact-form" class="btn btn-base form__button__btn js-slideDown" type="submit">Mám záujem</a>
                <div class="form__button__info">
                    <span class="form__button__info__text">Mesačná splátka</span>
                    <span class="form__button__info__value">{{result}}€</span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
name: "MortgageCalculator",
    props: {
        price: {
            type: String
        }
    },
    data() {
        return {
            localPrice: 0,
            payment: 30,
            rate: '1.0',
            result: 0,
        };
    },
    mounted() {
        this.localPrice = parseFloat(this.price);
        this.calculateResult();
    },
    methods: {
        calculateResult(){
            let p = this.localPrice;
            let r = 1+(parseFloat(this.rate)/100)/12;
            let r_t = Math.pow(r, parseInt(this.payment)*12);
            let result = 0;
            result = ((p*r_t)*(r-1))/(r_t-1);
            this.result = Math.round(result * 100) / 100 ;
        }
    }
}
</script>

<style scoped>

</style>
