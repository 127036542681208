
// window.Vue = require('vue');
// window.axios = require('axios');
// import SearchBox from './components/search/SearchBox.vue';
// Vue.component('searchBox', require('./components/search/SearchBox.vue').default);
//
// Vue.component('career', require('../js/components/career/Career.vue').default);
// Vue.component('contactForm', require('../js/components/contact/ContactForm.vue').default);
// Vue.component('mortgageCalculator', require('../js/components/mortgage/MortgageCalculator').default);
// Vue.component('offerReaction', require('../js/components/offer/OfferReaction').default);
// Vue.component('sellProperty', require('../js/components/offer/SellProperty').default);
// Vue.component('buyProperty', require('../js/components/offer/BuyProperty').default);

import Vue from 'vue';
import Axios from "axios";

import SearchBox from './components/search/SearchBox.vue';
import Career from "./components/career/Career";
import ContactForm from "./components/contact/ContactForm";
import MortgageCalculator from "./components/mortgage/MortgageCalculator";
import OfferReaction from "./components/offer/OfferReaction";
import SellProperty from "./components/offer/SellProperty";
import BuyProperty from "./components/offer/BuyProperty";

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

window.Vue = Vue;
window.axios = Axios;

// Vue.prototype.$http = Axios;
//
// Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);

new Vue({
    el: '#app',
    components: {
        SearchBox,
        Career,
        ContactForm,
        MortgageCalculator,
        OfferReaction,
        SellProperty,
        BuyProperty
        //
        // 'search-box': require('../js/components/search/SearchBox.vue').default,
        // 'career': require('../js/components/career/Career.vue').default,
        // // 'bModal': require('../js/components/bootstrap/modal').BModal,
        // 'contactForm': require('../js/components/contact/ContactForm').default,
        // 'mortgageCalculator': require('../js/components/mortgage/MortgageCalculator').default,
        // 'offerReaction': require('../js/components/offer/OfferReaction').default,
        // 'buyProperty': require('../js/components/offer/BuyProperty').default,
        // 'sellProperty': require('../js/components/offer/SellProperty').default
    }
});
