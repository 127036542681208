<template>
    <div class="form">
        <form v-if="!sent"  v-on:submit.prevent="submit">
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Meno: *</label>
                <input type="text" id="name" name="name" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="email" class="form__item__label">E-mail: *</label>
                <input type="email" id="email" name="email" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Telefónne číslo: *</label>
                <input type="tel" id="phone" name="phone" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="location" class="form__item__label">Lokalita: *</label>
                <select name="location" id="location" class="form__item__input form__item__select" required v-model="localCity">
                    <option>-</option>
                    <option v-for="branch in branches" :key="branch.id">{{branch}}</option>
                </select>
            </div>
            <div class="form__item__wrap">
                <label for="file" class="form__item__label">Váš životopis:</label>
                <label for="file" class="form__item__file">
                    <input type="file" id="file" name="file" ref="file" class="form__item__file__input--hidden" onchange="document.querySelector('.form__item__file__input').innerHTML = this.files[0].name;">
                    <div class="form__item__input form__item__file__input"></div>
                    <div for="file" class="btn btn-success form__item__file__btn">
                        <span>Nahrať</span>
<!--                        <img src="{{asset('img/icons/right-white.svg')}}" alt="" class="form__item__file__btn__icon">-->
                    </div>
                </label>
            </div>
            <div class="form__item__wrap">
                <div class="form__checkbox__wrap">
                    <label class="checkbox" for="checkbox_1">
                        <input name="checkbox_1" value="true" type="checkbox" id="checkbox_1" required>
                        <span class="checkbox-label">Súhlasím so spracovaním <span class="d-inline-block"><a href="#" target="_blank" class="checkbox-label__link">osobných údajov</a> *</span></span>
                    </label>
                </div>
            </div>
            <div class="form__button__wrap form__button__wrap--center">
                <button class="btn btn-primary form__button__btn" type="submit" >Odoslať</button>
            </div>
            <div v-if="successModal" class="form-status alert-success">
                Úspešne ste sa zaregistrovali.
            </div>
        </form>

    </div>
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import brands from "@fortawesome/fontawesome-free-brands";
import solid from "@fortawesome/fontawesome-free-solid";
import FontAwesomeIcon from "@fortawesome/vue-fontawesome";

fontawesome.library.add(brands, solid);
export default {
    name: "Career",
    components: {
        FontAwesomeIcon
    },
    props: {
        branches: {
            type: Array|Object
        },
        city: {
            type: String
        },
    },
    data() {
        return {
            localCity: null,
            sent: false,
            successModal: false,
        };
    },
    mounted() {
        this.localCity = this.city;
    },

    methods: {
        submit(postData) {
            let formData = new FormData();
            formData.append('name', document.querySelector('#name').value);
            formData.append('email', document.querySelector('#email').value);
            formData.append('phone', document.querySelector('#phone').value);
            formData.append('location', document.querySelector('#location').value);
            formData.append('file', this.$refs.file.files[0]);

            const requestOptions = {
                headers: {'Content-Type': 'multipart/form-data' },
            };

            axios.post('/kariera/post', formData, requestOptions)
                .then(() => {
                    this.successModal = true;
                    setTimeout( () => {
                        this.successModal = false;
                    }, 3000);
                })


        }
    }
}
</script>

<style scoped>

</style>
