<template>
    <form v-on:submit.prevent="submit" >
        <div class="filter__inner">
            <div class="filter__item">
                <span class="filter__placeholder">Kraj</span>
                <select name="county" class="filter__item__inner js-choice-county" v-model="county" @change="countyChanged">
                    <option value="-">-</option>
                    <option v-for="county in filtersLocal['counties']" :value="county['url']">{{county['name']}}</option>
                </select>
            </div>
            <div class="filter__item" >
                <span class="filter__placeholder">Okres</span>
                <select name="district" class="filter__item__inner js-choice-district" v-model="district" @change="districtChanged">
                    <option value="">-</option>
                    <option v-for="(dd ,index)  in districtOptions" :key="index"
                            :value="dd['url']"

                    >{{dd['name']}}</option>
                </select>
            </div>
            <div class="filter__item">
                <span class="filter__placeholder">Mesto</span>
                <select name="city" class="filter__item__inner js-choice-city" v-model="city">
                    <option value="">-</option>
                </select>
            </div>
            <div class="filter__item">
                <span class="filter__placeholder">Typ</span>
                <select name="object_type" class="filter__item__inner js-choice-object-type" v-model="objectType">
                    <option value="">-</option>
                </select>
            </div>
            <div class="filter__item">
                <span class="filter__placeholder">Druh</span>
                <select name="type" class="filter__item__inner js-choice-type" v-model="type">
                    <option value="">-</option>
                </select>
            </div>
            <div class="filter__item">
                <span class="filter__placeholder">Cena</span>
                <select name="price" class="filter__item__inner js-choice-price" v-model="price">
                    <option value="">-</option>
                </select>
            </div>
            <div class="filter__item">
                <div class="filter__button">
                    <button type="submit" name="submit" class="btn btn-base filter__btn">
                        <img :src="searchIcon" alt="" class="filter__btn__icon">
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

import Choices from 'choices.js';
export default {
    name: "SearchBox",
    props: {
        searchIcon: {
            type: String
        },
        filters: {
            type: Object|Array
        },
    },
    data() {
        return {
            filtersLocal: [],
            county: '',
            district: '',
            city: '',
            type: '',
            objectType: '',
            price: '',
            districtOptions: [],
            countyChoice: null,
            districtChoice: null,
            typeChoice: null,
            objectTypeChoice: null,
            cityChoice: null,
            priceChoice: null,
        }
    },
    // computed: {
    //     districtOptions() {
    //
    //     }
    // },


    mounted() {
        this.filtersLocal = JSON.parse(this.filters);
        // this.districtOptions = this.filtersLocal['all_districts'];

        const config = {
            searchEnabled: false,
            searchChoices: false,
            shouldSort: false,
            placeholder: false,
            loadingText: 'Načítavanie...',
            noResultsText: 'Žiadne výsledky',
            noChoicesText: 'Žiadne výsledky',
            itemSelectText: 'Vybrať',
            position: 'bottom',
            callbackOnInit: document.querySelector('.filter').classList.add('initialized'),
        };


        let countyChoiceElement = document.querySelector('.js-choice-county');
        let districtChoiceElement = document.querySelector('.js-choice-district');
        let typeChoiceElement = document.querySelector('.js-choice-type');
        let objectTypeChoiceElement = document.querySelector('.js-choice-object-type');
        let cityChoiceElement = document.querySelector('.js-choice-city');
        let priceChoiceElement = document.querySelector('.js-choice-price');

        this.countyChoice = new Choices(countyChoiceElement, config);
        // this.filtersLocal['counties'].unshift({label: '-', pattern:'-', value: '-'})
        this.countyChoice.setChoices(this.filtersLocal['counties']);

        this.districtChoice = new Choices(districtChoiceElement, config);

        this.typeChoice = new Choices(typeChoiceElement, config);
        this.typeChoice.setChoices(this.filtersLocal['type']);

        this.cityChoice = new Choices(cityChoiceElement, config);

        this.objectTypeChoice = new Choices(objectTypeChoiceElement, config);
        this.objectTypeChoice.setChoices(this.filtersLocal['object_type']);

        this.priceChoice = new Choices(priceChoiceElement, config);
        this.priceChoice.setChoices(this.filtersLocal['price']);


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        this.county = urlParams.get('county') ?  urlParams.get('county') : '-';

        this.district = urlParams.get('district');
        this.type = urlParams.get('type');
        this.city = urlParams.get('city');
        console.log(this.city);
        this.objectType = window.location.pathname.split('/').pop();
        this.price = urlParams.get('price');
        for(let i = 0; i < this.filtersLocal['counties'].length; i++) {
            if(this.filtersLocal['counties'][i] && this.filtersLocal['counties'][i].value === this.county) {
                this.countyChoice.setValue([this.filtersLocal['counties'][i]]);
                this.countyChanged({ target: {value: this.county}});
                break;
            }
        }

        for(let i = 0; i < this.filtersLocal['all_districts'].length; i++) {

            if(this.filtersLocal['all_districts'][i] && this.filtersLocal['all_districts'][i].value === this.district) {
                this.districtChoice.setValue([this.filtersLocal['all_districts'][i]]);
                this.districtChanged({ target: {value: this.district}});
                break;
            }
        }

        for(let i = 0; i < this.filtersLocal['type'].length; i++) {
            if(this.filtersLocal['type'][i] && this.filtersLocal['type'][i].value === this.type) {
                this.typeChoice.setValue([this.filtersLocal['type'][i]]);
                break;
            }
        }

        for(let i = 0; i < this.filtersLocal['object_type'].length; i++) {
            if(this.filtersLocal['object_type'][i] && this.filtersLocal['object_type'][i].value === this.objectType) {
                this.objectTypeChoice.setValue([this.filtersLocal['object_type'][i]]);
                break;
            }
        }

        for(let i = 0; i < this.filtersLocal['price'].length; i++) {
            if(this.filtersLocal['price'][i] && this.filtersLocal['price'][i].value === this.price) {
                this.priceChoice.setValue([this.filtersLocal['price'][i]]);
                break;
            }
        }

    },
    methods: {
        countyChanged(e) {
            let newCounty = e.target.value;
            console.log(newCounty);
            if(newCounty === '-') {
                this.districtChoice.clearChoices();
                this.cityChoice.clearChoices();

                this.districtChoice.setValue(['-']);
                this.cityChoice.setValue(['-']);

                console.log(this.district);

                this.district = '';
                this.city = '';
                return
            }

            if(newCounty.length > 0) {
                let result = [];
                for(let i = 0; i < this.filtersLocal['all_districts'].length; i++) {
                    if(this.filtersLocal['all_districts'][i].parent === newCounty) {
                        result.push(this.filtersLocal['all_districts'][i]);
                    }
                }
                this.districtOptions = result;
            }
            this.districtChoice.clearChoices();
            this.districtOptions.unshift({ label: '-', pattern:'-', value:''});
            this.districtChoice.setChoices(this.districtOptions)
        },

        districtChanged(e) {
            let newDisctrict = e.target.value;
            console.log(newDisctrict);
            if(newDisctrict === '') {
                this.cityChoice.clearChoices();
                // this.cityChoice.setChoices([{ label: '-', pattern:'-', value:''}]);
                this.cityChoice.setValue([{ label: '-', pattern:'-', value:''}]);
                this.city = '-';
                return
            }
            if(newDisctrict.length > 0) {
                axios.get(`/api/${newDisctrict}/cities`)
                    .then( result => {
                        console.log(result.data);
                        this.cityOptions = result.data;
                        this.cityChoice.clearChoices();
                        this.cityOptions.unshift({ label: '-', pattern:'-', value:''});
                        this.cityChoice.setChoices(this.cityOptions);

                        for(let i = 0; i < this.cityOptions.length; i++) {
                            if(this.cityOptions[i] && this.cityOptions[i].value === this.city) {
                                this.cityChoice.setValue([this.cityOptions[i]]);
                            }
                        }
                    });

            }

        },

        submit() {
            let type = this.objectType.length > 0 ? this.objectType : 'vsetky';
            let url = '/ponuka/'+type;
            let params = '';
            if(this.county) {
                params = params.length > 0 ? params +'&' : '?';
                params += 'county='+this.county;
            }
            if(this.district) {
                params = params.length > 0 ? params +'&' : '?';
                params += 'district='+this.district;
            }
            if(this.city) {
                params = params.length > 0 ? params +'&' : '?';
                params += 'city='+this.city;
            }
            if(this.type) {
                params = params.length > 0 ? params +'&' : '?';
                params += 'type='+this.type;
            }
            if(this.price) {
                params = params.length > 0 ? params +'&' : '?';
                params += 'price='+this.price;
            }
            window.location.href = url + params;
        }
    }
}
</script>

<style scoped>

</style>
