<template>
    <div class="form">
        <form method="POST" v-on:submit.prevent="submit">
            <div class="form__item__wrap">
                <label for="location" class="form__item__label">Ktorú pobočku chcete kontaktovať: *</label>
                <select name="location" id="location" class="form__item__input form__item__select" required v-model="localCity">
                    <option v-for="branch in branches" :key="branch.id" >{{ branch }}</option>
                </select>
            </div>
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Meno: *</label>
                <input type="text" id="name" name="name" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="email" class="form__item__label">E-mail: *</label>
                <input type="email" id="email" name="email" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Telefónne číslo: *</label>
                <input type="tel" id="phone" name="phone" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="message" class="form__item__label">Správa: *</label>
                <textarea name="message" id="message" rows="5" class="form__item__input form__item__textarea"></textarea>
            </div>
            <div class="form__item__wrap">
                <div class="form__checkbox__wrap">
                    <label class="checkbox" for="checkbox_1">
                        <input name="checkbox_1" value="true" type="checkbox" id="checkbox_1" required>
                        <span class="checkbox-label">Súhlasím so spracovaním <span class="d-inline-block"><a href="#" target="_blank" class="checkbox-label__link">osobných údajov</a> *</span></span>
                    </label>
                </div>
            </div>
            <div class="form__button__wrap form__button__wrap--center">
                <button class="btn btn-primary form__button__btn" type="submit">Odoslať</button>
            </div>
            <div v-if="successModal" class="form-status alert-success">
                Dakujeme za Vašu správu.
            </div>

        </form>
    </div>
</template>

<script>
export default {
    name: "ContactForm",
    props: {
        branches: {
            type: Array|Object
        },
        city: {
            type: String
        }
    },
    data() {
        return {
            localCity: null,
            successModal: false,
        }
    },
    mounted() {
        this.localCity = this.city;
    },
    methods: {
        submit() {
            let formData = new FormData();
            formData.append('location', document.querySelector('#location').value);
            formData.append('name', document.querySelector('#name').value);
            formData.append('email', document.querySelector('#email').value);
            formData.append('phone', document.querySelector('#phone').value);
            formData.append('message', document.querySelector('#message').value);

            const requestOptions = {
                headers: {'Content-Type': 'application/json' },
            };

            axios.post('/kontakt/post', formData, requestOptions)
                .then(() => {
                    this.successModal = true;
                    setTimeout( () => {
                        this.successModal = false;
                    }, 3000);
                })

        }
    }
}
</script>

<style scoped>

</style>
