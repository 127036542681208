<template>
    <div class="form">
        <form @submit.prevent="submit">
            <div class="form__item__wrap">
                <label for="location" class="form__item__label">Koho chcete kontaktovať: *</label>
                <select name="location" id="location" class="form__item__input form__item__select" required v-model="localCity">
                    <option v-for="branch in branches" :key="branch.id">{{ branch }}</option>
                </select>
            </div>
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Meno: *</label>
                <input type="text" id="name" name="name" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="email" class="form__item__label">E-mail: *</label>
                <input type="email" id="email" name="email" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="phone" class="form__item__label">Telefónne číslo: *</label>
                <input type="tel" id="phone" name="phone" class="form__item__input" required>
            </div>
            <!-- extra -->
            <div class="form__item__wrap">
                <label for="type" class="form__item__label">Typ:</label>
                <select name="type" id="type" rows="5" class="form__item__input form__item__select">
                    <option v-for="type in types" :key="type.id">{{type}}</option>
                </select>
            </div>
            <div class="form__item__wrap">
                <label for="city" class="form__item__label">Obec:</label>
                <input name="city" id="city" rows="5" class="form__item__input form__item__input"/>
            </div>

            <div class="form__item__wrap">
                <label for="size" class="form__item__label">Veľkosť:</label>
                <input name="size" id="size"  class="form__item__input form__item__input"/>
            </div>
            <div class="form__item__wrap">
                <label for="price" class="form__item__label">Cena:</label>
                <input name="price" id="price"  class="form__item__input form__item__input"/>
            </div>


            <div class="form__item__wrap">
                <label for="message" class="form__item__label">Správa: *</label>
                <textarea name="message" id="message" rows="5" class="form__item__input form__item__textarea"></textarea>
            </div>
            <div class="form__item__wrap">
                <div class="form__checkbox__wrap">
                    <label class="checkbox" for="checkbox_1">
                        <input name="checkbox_1" value="true" type="checkbox" id="checkbox_1" required>
                        <span class="checkbox-label">Súhlasím so spracovaním <span class="d-inline-block"><a href="#" target="_blank" class="checkbox-label__link">osobných údajov</a> *</span></span>
                    </label>
                </div>
            </div>
            <div class="form__button__wrap form__button__wrap--center">
                <button class="btn btn-primary form__button__btn" type="submit">Odoslať</button>
            </div>
            <div v-if="successMessage" class="form-status alert-success">Dakujeme, budeme Vás kontaktovať.</div>
        </form>
    </div>
</template>

<script>
export default {
    name: "BuyProperty",
    props: {
        city: {
            type: String,
        },
        branches: {
            type: Array|Object
        },
        types: {
            type: Array|Object
        }
    },
    data(){
        return {
            localCity: '',
            successMessage: false,
        }
    },
    mounted() {
        this.localCity = this.city;
    },
    methods: {
        submit() {
            let formData = new FormData;
            let location = document.querySelector('#location').value;
            let name = document.querySelector('#name').value;
            let email = document.querySelector('#email').value;
            let phone = document.querySelector('#phone').value;
            let message = document.querySelector('#message').value;
            let checkbox_1 = document.querySelector('#checkbox_1').checked;

            let type = document.querySelector('#type').value;
            let city = document.querySelector('#city').value;
            let price = document.querySelector('#price').value;
            let size = document.querySelector('#size').value;


            formData.append('location', location);
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('message', message);
            formData.append('gdpr', checkbox_1);

            formData.append('type', type);
            formData.append('city', city);
            formData.append('price', price);
            formData.append('size', size);


            const requestOptions = {
                headers: {'Content-Type': 'application/json' },
            };

            axios.post(window.location.pathname + '/post', formData, requestOptions)
                .then(() => {
                    this.successMessage = true;
                    setTimeout( () => {
                        this.successMessage = false;
                    }, 3000);
                });
        }
    }
}
</script>

<style scoped>

</style>
