<template>
    <div class="form">
        <form @submit.prevent="submit">
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Meno: *</label>
                <input type="text" id="name" name="name" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="email" class="form__item__label">E-mail: *</label>
                <input type="email" id="email" name="email" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <label for="name" class="form__item__label">Telefónne číslo: *</label>
                <input type="tel" id="phone" name="phone" class="form__item__input" required>
            </div>
            <div class="form__item__wrap">
                <div class="form__checkbox__wrap">
                    <label class="checkbox" for="checkbox_1">
                        <input name="checkbox_1" value="true" type="checkbox" id="checkbox_1" required>
                        <span class="checkbox-label">Súhlasím so spracovaním <span class="d-inline-block"><a href="#" target="_blank" class="checkbox-label__link">osobných údajov</a> *</span></span>
                    </label>
                </div>
                <div class="form__checkbox__wrap">
                    <label class="checkbox" for="checkbox_2">
                        <input name="checkbox_2" value="true" type="checkbox" id="checkbox_2">
                        <span class="checkbox-label">Mám záujem o ďalšie informácie.</span>
                    </label>
                </div>
                <div class="form__checkbox__wrap">
                    <label class="checkbox" for="checkbox_3">
                        <input name="checkbox_3" value="true" type="checkbox" id="checkbox_3">
                        <span class="checkbox-label">Mám záujem o obhliadku nehnuteľnosti.</span>
                    </label>
                </div>
            </div>
            <div class="form__button__wrap form__button__wrap--center">
                <button class="btn btn-primary form__button__btn" type="submit">Odoslať</button>
            </div>
            <div v-if="successMessage" class="form-status alert-success">Dakujeme za Váš email, budeme Vás kontaktovať.</div>
        </form>
    </div>
</template>

<script>
export default {
    name: "OfferReaction",
    data() {
        return {
            successMessage: false
        };
    },
    methods: {
        submit(){
            let price = document.querySelector('#price').value;
            let payment = document.querySelector('#payment').value;
            let rate = document.querySelector('#rate').value;
            let name = document.querySelector('#name').value;
            let email = document.querySelector('#email').value;
            let phone = document.querySelector('#phone').value;
            let gdpr = document.querySelector('#checkbox_1').checked;
            let newsletter = document.querySelector('#checkbox_2').checked;
            let inspection = document.querySelector('#checkbox_3').checked;

            let formData = new FormData;
            formData.append('price', price);
            formData.append('payment', payment);
            formData.append('rate', rate);
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('gdpr', gdpr);
            formData.append('newsletter', newsletter);
            formData.append('inspection', inspection);
            formData.append('url', window.location.href);

            const requestOptions = {
                headers: {'Content-Type': 'application/json' },
            };

            axios.post('/reakcia/post', formData, requestOptions)
                .then(() => {
                    this.successMessage = true;
                    setTimeout( () => {
                        this.successMessage = false;
                    }, 3000);
                })
        }
    },
}
</script>

<style scoped>

</style>
